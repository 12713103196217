<template>
  <v-card color="#F5F5F5">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5 text-center">
          <v-icon>{{icon}}</v-icon> {{count}}
        </v-list-item-title>
        <v-list-item-subtitle class="text-center purple--text">{{title}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
    name: 'StatCard',
    props: [
        'count', 'title', 'icon'
    ]
}
</script>

<style>

</style>