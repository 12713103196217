<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        cols="12"
        class="mb-5"
        lg="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    DASHBOARD
                </v-card-title>
                <v-card-subtitle>
                    An overview of your school's results statistics
                </v-card-subtitle>
                <v-card-text>
                    <info-loader-2 v-if="fetchingContent"/>
                    <v-row v-else>
                        <v-col cols="12" sm="6" md="4"  v-for="(stat, i) in stats" :key="i">
                            <stat-card :count="stat.count" :title="stat.title" :icon="stat.icon"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="mt-2">
                            <a class="link" href="https://www.youtube.com/watch?v=PjH4QiOVwPM" target="_blank"> <v-icon>mdi-video-vintage</v-icon> Watch how the software works</a>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderVue from '../../components/Header.vue'
import axios from 'axios';
import StatCard from '../../components/StatCard.vue';
import InfoLoader2 from '../../components/InfoLoader2.vue';
  export default {
    name: 'Dashboard',
    components: {
        HeaderVue,
        StatCard,
        InfoLoader2
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.sessionStats()
    },
    data: () => ({
        school: localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        authToken:sessionStorage.getItem('authToken') ?? null,
        stats: [],
        fetchingContent:true
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        sessionStats () {
            //let loader = this.$loading.show()
            axios.get(process.env.VUE_APP_BASEURL+'/parameters?school_id='+this.school.school_id,
            {
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
            })
            .then((response) => {
                this.stats.push({
                    title:'Current session',
                    count:response.data.data[0].session.session,
                    icon:'mdi-circle-slice-5',
                })
                this.stats.push({
                    title:'Current term',
                    count:response.data.data[0].term,
                    icon:'mdi-pound-box',
                })
                this.stats.push({
                    title:'This term students Results',
                    count:response.data.data[0].total_students_with_results,
                    icon:'mdi-newspaper-variant-multiple',
                })
                if (this.school.plan !== 'Free') {
                    this.stats.push({
                        title:'This term payment due',
                        count:'-',
                        //count:response.data.data[0].currency+' '+response.data.data[0].amount_due,
                        icon:'mdi-cash',
                    })
                    this.stats.push({
                        title:'This term payment paid',
                        //count:response.data.data[0].currency+' '+response.data.data[0].paid_this_term,
                        count:'-',
                        icon:'mdi-cash',
                    })
                    this.stats.push({
                        title:'Last Payment recorded',
                        count:'-',
                        //count:this.school.last_payment,
                        icon:'mdi-timetable',
                    })
                }
                this.fetchingContent = false;
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                //loader.hide()
            });
        },
    },
  }
</script>

<style scoped>
.link{
    text-decoration: none;
}
</style>
